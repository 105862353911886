* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Space Mono', monospace;
    color-scheme: dark;
  }
  
  :root {
    --primary-color: rgba(132, 176, 103);
    --text-color: rgb(179, 179, 179);
    --background-color: rgb(12, 12, 12);
    --section-spacing: 80px;
    --element-spacing: 20px;
  }
  
  body {
    background-color: var(--background-color);
    line-height: 1.6;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: var(--element-spacing);
    color: var(--primary-color);
  }
  
  p {
    margin-bottom: var(--element-spacing);
    color: var(--text-color);
  }
  
  a {
    color: var(--primary-color);
    text-decoration: none;
    transition: opacity 0.3s ease;
  }
  
  a:hover {
    opacity: 0.8;
  }
  
  /* Common containers */
  .section-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  /* Utility classes */
  .mb-1 { margin-bottom: 10px; }
  .mb-2 { margin-bottom: 20px; }
  .mb-3 { margin-bottom: 40px; }
  .mb-4 { margin-bottom: 60px; }
  .mb-5 { margin-bottom: 80px; }
  
  /* .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .products {
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  } */