.footer {
    background: rgb(12, 12, 12);
    color: #fff;
    padding: 40px 0 20px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer-section {
    width: 30%;
    margin-bottom: 20px;
  }
  
  .footer-section h3 {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: rgba(132, 176, 103);
  }
  
  .footer-section p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .social-icons {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .social-icons li {
    margin-right: 15px;
  }
  
  .social-icons a {
    color: #fff;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: rgba(132, 176, 103);
  }
  
  .copyright {
    text-align: center;
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 0.9rem;
  }
  
  @media screen and (max-width: 960px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
    
    .footer-section {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
    
    .social-icons {
      justify-content: center;
    }
  }