.title {
    background-image: url('../images/foggy_forest.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 8% 10%;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    text-align: left;
}

.title h1 {
    color: rgba(255, 255, 255, 0.90);
    margin: 0 0 20px;
    font-size: 2.5rem;
    max-width: 900px;
    margin-left: 0;
    margin-right: auto;
    font-weight: 500;
}

.title h2,
.title h3 {
    color: rgba(255, 255, 255, 0.80);
    margin: 0 0 10px;
    font-size: 1.2rem;
    font-weight: 300;
    max-width: 800px;
    margin-left: 0;
    margin-right: auto;
}

article {
    padding: 5% 10%;
    background-color: rgb(12, 12, 12);
    min-height: 80vh;
    max-width: 1200px;
    margin: 0 auto;
}

article p {
    margin-bottom: 20px;
    color: rgb(179, 179, 179);
    font-size: 1.1rem;
    line-height: 1.6;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    max-width: 800px;
    margin-left: 0;
    margin-right: auto;
    text-align: left;
}

article h2 {
    color: rgba(132, 176, 103, 0.9);
    font-size: 1.8rem;
    margin: 40px auto 20px;
    margin-left: 0;
    max-width: 800px;
    text-align: left;
}

article h3 {
    color: rgba(132, 176, 103, 0.8);
    font-size: 1.4rem;
    margin: 30px auto 15px;
    margin-left: 0;
    max-width: 800px;
    text-align: left;
}

article ul, 
article ol {
    max-width: 750px;
    margin: 0 auto 20px;
    margin-left: 0;
    color: rgb(179, 179, 179);
    font-size: 1.1rem;
    line-height: 1.6;
    text-align: left;
}

article li {
    margin-bottom: 10px;
}

article img {
    margin: 30px auto;
    display: block;
    max-width: 100%;
    width: auto;
    max-height: 600px;
    padding: 0;
    height: auto;
    border: none;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

article iframe {
    margin: 30px auto;
    display: block;
    max-width: 100%;
    width: 800px;
    padding: 0;
    aspect-ratio: 16 / 9; /* This ensures the 16:9 aspect ratio */
    height: auto;
    border: none;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

article video {
    margin: 30px auto;
    display: block;
    max-width: 100%;
    width: 800px;
    padding: 0;
    height: auto;
    border: none;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

article a {
    display: block;
    margin: 20px auto;
    margin-left: 0;
    text-decoration: none;
    color: rgba(132, 176, 103, 0.9);
    font-size: 1.1rem;
    transition: color 0.3s ease;
    text-align: left;
    max-width: 800px;
}

article a:hover {
    color: rgba(132, 176, 103, 1);
    text-decoration: underline;
}

/* Media queries for responsive design */
@media only screen and (max-width: 768px) {
    .title h1 {
        font-size: 2rem;
    }
    
    article p,
    article ul,
    article ol,
    article a {
        font-size: 1rem;
    }
    
    article h2 {
        font-size: 1.6rem;
    }
    
    article h3 {
        font-size: 1.3rem;
    }
}