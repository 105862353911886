.cards {
  padding: 4rem 2rem;
  background: rgb(12, 12, 12);
}

.cards__header {
  text-align: center;
  margin-bottom: 3rem;
  color: rgba(132, 176, 103);
}

.cards__header h1 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
}

.cards__header p {
  font-size: 1.2rem;
  color: rgb(179, 179, 179);
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 20px 0 45px;
  width: 100%;
}

.cards__items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 2rem;
  margin-bottom: 2.5rem;
  width: 100%;
}

.cards__item {
  display: flex;
  flex: 1;
  border-radius: 12px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
  background-color: rgba(20, 20, 20, 0.8);
  height: 100%;
}

.cards__item:hover {
  transform: translateY(-10px);
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.5), 
              0 5px 15px rgba(132, 176, 103, 0.4);
}

.cards__item:hover .cards__item__img {
  filter: grayscale(0%);
  transform: scale(1.05);
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  text-decoration: none;
  border-radius: 12px;
  overflow: hidden;
  background-color: rgb(20, 20, 20);
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 65%;
  overflow: hidden;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
  filter: grayscale(40%);
}

.cards__item__info {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cards__item__text {
  color: white;
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 0.8rem;
}

.cards__item__date {
  color: rgba(132, 176, 103, 0.8);
  font-size: 0.9rem;
  margin-top: auto;
}

.cards__item__tags {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8px;
  z-index: 2;
  max-width: 70%;
  pointer-events: none;
}

.cards__item__tag {
  background-color: rgba(132, 176, 103, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  white-space: nowrap;
  pointer-events: auto;
}

@media only screen and (max-width: 1024px) {
  .cards {
    padding: 2rem 1rem;
  }
  
  .cards__header h1 {
    font-size: 2.2rem;
  }
  
  .cards__items {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
  }
  
  .cards__item__text {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .cards__items {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto 2rem;
  }
}
